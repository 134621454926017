import { isProd } from "@lib/costants";

const STAG_CATEGORY_IDS = {
  companies: "d9004ef7-fa73-4130-9941-1a422ad7b7ff",
  publicAdministrations: "197de1f8-09cd-40f9-8c40-551b7c7cbe2f",
  nextFocus: "16250bdf-9de7-4b2e-9bad-cd8b97ec561d",
  nextFocusWhitepaper: "55f82dc4-add5-4879-bddd-623652a10ee2",
  nextStories: "3f353e8e-b812-425b-821a-7af5ccda2150",
  nextNews: "715ad6dd-508d-4101-912a-e150ad9a5895",
};

const PROD_CATEGORY_IDS = {
  companies: "83113056-f228-4a14-a437-899fa4f302aa",
  publicAdministrations: "d789ac64-5c74-4ca0-9e10-e1033737d2a0",
  nextFocus: "",
  nextFocusWhitepaper: "",
  nextStories: "",
  nextNews: "",
};

const categoryIdsMap = () => {
  if (isProd) return PROD_CATEGORY_IDS;
  return STAG_CATEGORY_IDS;
};

export const cardArticleComplete: string[] = ['next focus', 'white paper', 'webinar', 'report', 'long form', 'next stories','next generations', 'next voices']
export const focusCategory: string[] = ['next focus', 'white paper', 'webinar', 'report', 'long form']
export const isFocusCategory = (name: string): boolean => focusCategory.includes(name?.toLowerCase())
export const isCardComplete = (name: string): boolean => cardArticleComplete.includes(name?.toLowerCase())

export default categoryIdsMap;