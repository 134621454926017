import Image from "next/legacy/image";

import { convertDate } from "@helper/date";
import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

import EastIcon from '@mui/icons-material/East';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useTranslation } from "next-i18next";
import Link from "next/link";
import { CardProps } from ".";
import styles from './../Article/Article.module.scss';
import theme from "@styles/mui/theme";


const categoriesColor = {
  // Stories and children
  "next-stories": theme.palette.secondary.main,
  "next-generations": theme.palette.secondary.main,
  "next-voices": theme.palette.secondary.main,

  // Focus and children
  "next-focus": theme.palette.primary.main,
  "white-paper": theme.palette.primary.main,
  "webinar": theme.palette.primary.main,
  "report": theme.palette.primary.main,
}

export const CardComplete: React.FC<CardProps> = ({ node }) => {
  const {t} = useTranslation();
  const image: string = node?.field_article_image ? getThumbnailUrl(node.field_article_image) : "/assets/images/img-placeholder-01.jpg";
  const date: string = convertDate(node?.created, node.path.langcode);

  const categoryName: string = node?.field_category?.name
  const categoryColor = categoriesColor[categoryName.toLowerCase().replaceAll(" ", "-")]

  return (
    
    <Link href={node?.path?.alias} passHref>
      <Box className={styles.articleCard}>
        {categoryName && (
          <Box
            sx={{
              bgcolor: categoryColor,
              position: 'absolute',
              top: 8,
              px: 24,
              py: 4,
              borderTopRightRadius: 8,
              zIndex: 10
          }}>
            <Typography variant="overline" color={'common.white'}>{categoryName}</Typography>
          </Box>
        )}
        {image && <Image src={image} alt={getThumbnailAlt(node.field_article_image)} width={392} height={220} layout="responsive" objectFit="cover" quality={90} />}
        
        <Box className={styles.content}>
          <Typography variant="bodyM" fontWeight="light" color="text.secondary">{date}</Typography>
          <Typography variant="subtitle" component='h5' className={styles.title}>{node.title}</Typography>
        </Box>

        <Box className={styles.button}>
          <Button 
            variant="link"
            startIcon={<EastIcon color="primary" />}
          >{t("news.readAll")}</Button>
        </Box>

      </Box>
    </Link>
  )
}

export default CardComplete